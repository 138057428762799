<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon v-if="!printLoading" type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; margin-bottom: 20px; float: right"
          :fields="fields"
          :data="csvToExport"
          :name="'listeReglement.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
        <a-button type="user-add" @click="link">
          <a-icon type="user-add" @click="link" />{{
            $t("liste.ajouterDossier")
          }}
        </a-button>
        <a-range-picker
          style="margin-left: 20px"
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD-MM-YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline">
              <div v-if="record.fees">
                <a-tag
                  color="blue"
                  v-for="(value, key) in record.fees"
                  :key="key"
                  >{{ formatFeeName(key) }}</a-tag
                >
              </div>
            </div>
          </template>
          <a-tag color="#2db7f5" slot="userName" slot-scope="text">
            {{ text ? text : "--" }}
          </a-tag>
          <template slot="receiptType" slot-scope="text, record">
            <a-button
              icon="search"
              type="primary"
              block
              v-if="record.cheque"
              @click="chequeDetailsModal(record)"
            >
              {{
                text == "espéce"
                  ? $t("recette.espece")
                  : text == "chèque"
                  ? $t("recette.cheque")
                  : text == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : text
              }}
            </a-button>
            <div v-else>
              {{
                text == "espéce"
                  ? $t("recette.espece")
                  : text == "chèque"
                  ? $t("recette.cheque")
                  : text == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : text
              }}
            </div>
          </template>
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-button
            slot="annulation"
            type="danger"
            :loading="loadingAnnulation"
            :disabled="loadingAnnulation"
            slot-scope="text, record"
            @click="
              () => {
                selectedRecord = record;
                visibleCancelModal = true;
              }
            "
          >
            <a-icon type="delete" href="javascript:;" />{{
              $t("recette.annulation")
            }}
          </a-button>

          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("recette.imprimer") }}
          </a-button>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
      @cancel="handleOk"
    >
      <div v-if="activeCheque.montantCheque || activeCheque.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantCheque") }} :</span
              >
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3" v-if="activeCheque.montantEspece">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantEspece") }} :</span
              >
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>

      <div>
        <div v-if="loadedChequeData">
          <a-descriptions>
            <a-descriptions-item>
              <template v-slot:label>
                <span class="ant-descriptions-item-label">{{
                  $t("recette.chequeMontantTotal")
                }}</span>
              </template>
              <a-tag
                >{{
                  loadedChequeData.montant || activeCheque.montantCheque
                }}
                TND</a-tag
              >
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions>
            <a-descriptions-item
              stlye="width:100%"
              v-if="
                loadedChequeData.relatedTransactions &&
                loadedChequeData.relatedTransactions.length > 0
              "
            >
              <template v-slot:label>
                <span class="ant-descriptions-item-label">{{
                  $t("recette.relatedPayments")
                }}</span>
              </template>
              <table class="table table-striped" stlye="width:100%">
                <thead>
                  <tr>
                    <th>{{ $t("recette.numSerie") }}</th>
                    <th>{{ $t("comptabilite.nom") }}</th>
                    <th>{{ $t("recette.montantCheque") }} TND</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="t in loadedChequeData.relatedTransactions"
                    :key="t._id"
                  >
                    <td>{{ t.serial }}</td>
                    <td>
                      {{
                        t.typeAS == "A"
                          ? "Activity/" + t.payment.activity.designation
                          : "Scolarité/" + t.payment.classroom.name
                      }}
                    </td>
                    <td>{{ t.montantCheque }}</td>
                  </tr>
                </tbody>
              </table>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div v-else>
          <a-skeleton active :paragraph="{ rows: 1 }" />
        </div>
        <a-divider></a-divider>
      </div>

      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{ moment(activeCheque.dateEchance).format("DD/MM/YYYY") }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="moment(activeCheque.dateEchance).fromNow()"
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
    <a-modal
      v-model="visibleCancelModal"
      :title="$t('paiement.annulation')"
      @ok="
        () => {
          annulation(selectedRecord);
          visibleCancelModal = false;
        }
      "
    >
      <a-label>{{ $t("recette.type") }} : </a-label>
      <div class="text-center">
        <span class="ml-2 mr-2">{{ $t("paiement.avoir") }}</span>
        <a-switch
          :class="cancelType == 'Avoir' ? 'yellow-switch' : 'red-switch'"
          default-checked
          @change="
            (val) => {
              cancelType = val ? 'Annulation' : 'Avoir';
              visibleCancelModal = true;
            }
          "
        />
        <span class="ml-2 mr-2">{{ $t("paiement.annulation") }}</span>
      </div>
      <a-label>{{ $t("paiement.note") }} : </a-label>
      <a-textarea
        v-model="cancelNote"
        :placeholder="$t('paiement.note')"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import "jspdf-autotable";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import JsonCsv from "vue-json-csv";
import { formatFeeName } from "@/utilities/settings";
import { pdfHeader, printTransaction } from "@/utilities/pdfUtils";

Vue.use(VueRouter);

export default {
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        let elems = [];
        if (item.fees)
          for (const key of Object.keys(item.fees)) {
            elems.push(formatFeeName(key));
          }

        return {
          serial: item.serial,
          nomClient: item.nomClient,
          typeReglement: item.typeReglement,
          paiementSur: elems,
          receiptType: item.receiptType,
          montantHT: item.montantHT,
          montantTTC: item.montantTTC,
          datePaiement: moment(item.date).format("DD/MM/YYYY"),
        };
      });
    },
  },
  components: {
    JsonCsv,
  },
  async created() {
    await this.getTransactions();

    this.cacheData = this.data.map((item) => ({ ...item }));
    this.rowData = this.data.map((item) => ({ ...item }));
    this.tableLoading = false;
    this.filtredTable = this.data.map((item) => {
      item.montantTTC = item.montantTTC.toFixed(2);
      item.montantHT = item.montantHT.toFixed(2);
      return item;
    });
    this.filtredTableCache = this.filtredTable;
  },

  data() {
    return {
      loadedChequeData: null,
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      fields: [
        "serial",
        "nomClient",
        "typeReglement",
        "paiementSur",
        "receiptType",
        "montantHT",
        "montantTTC",
        "datePaiement",
      ],

      rowData: [],
      selectedRecord: {},
      visibleCancelModal: false,
      cancelType: "Annulation",
      cancelNote: "",
      tableLoading: true,
      data: [],
      chequeVisible: false,
      activeCheque: {},
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) => {
            let result = false;
            try {
              for (const key of Object.keys(record.fees)) {
                if (
                  formatFeeName(key).toLowerCase().includes(value.toLowerCase())
                )
                  result = true;
              }
            } catch {
              result = true;
            }
            return result;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.receiptType"),
          dataIndex: "receiptType",
          key: "receiptType",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "receiptType",
          },
          onFilter: (value, record) => {
            if (record.cheque) {
              return (
                record.receiptType
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return;
              record.receiptType.toLowerCase().includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHT"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) => {
            return record.userName
              ? record.userName.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
        {
          title: this.$t("recette.annulation"),
          dataIndex: "annulation",
          key: "annulation",
          fixed: "right",
          scopedSlots: {
            customRender: "annulation",
          },
        },
      ],
      filtredTable: [],
      loadingAnnulation: false,
      filtredTableCache: [],
      printLoading: false,
    };
  },
  methods: {
    formatFeeName,
    //TODO: FIx date range filter , on tranches month repartition
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    async getTransactions() {
      await apiClient
        .get("/transaction")
        .then((res) => {
          this.data = res.data;
        })
        .catch();
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheque = recordData.cheque;
      this.activeCheque.montantCheque = recordData.montantCheque;
      this.activeCheque.montantEspece = recordData.montantEspece;
      this.chequeVisible = true;
      this.activeCheque.transactionId = recordData._id;
      this.getChequeDetails(recordData.cheque._id);
    },
    getChequeDetails(id) {
      apiClient
        .get("/cheque/" + id)
        .then((res) => {
          this.loadedChequeData = res.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleOk(e) {
      this.chequeVisible = false;
      this.loadedChequeData = null;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    moment,

    link() {
      this.$router.push({ path: "reglementCaisse" });
    },
    annulation(record) {
      this.loadingAnnulation = true;

      apiClient
        .post("/transaction/cancel/" + record._id, {
          clientName: record.nomClient,
          name: record.typeReglement,
          type: this.cancelType,
          note: this.cancelNote,
        })
        .then((res) => {
          this.tableLoading = true;
          this.data = this.data.filter((elem) => elem._id != record._id);
          this.cacheData = this.cacheData.filter(
            (elem) => elem._id != record._id
          );
          this.rowData = this.rowData.filter((elem) => elem._id != record._id);
          this.$message.success("Le règlement a été annulé");
          this.tableLoading = false;
        })
        .catch((err) => {
          this.$message.error("Une erreur est survenue");
        })
        .finally(() => {
          this.loadingAnnulation = false;
        });
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    print(record) {
      this.$gtag.event("Imp one reglement", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      printTransaction(record, this.settings);
    },
    generatePdf() {
      this.$gtag.event("Imp listeReglement", {
        event_category: "Impression PDF",
        event_label: "comptabilite:listeReglement",
        value: 1,
      });

      this.printLoading = true;

      var titre = "Liste réglement";
      const doc = pdfHeader(this.settings, null, titre);

      var arr = [];
      const array = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].nomClient);
        arr.push(this.filtredTable[i].typeReglement);
        arr.push(this.filtredTable[i].receiptType);
        arr.push(this.filtredTable[i].montantHT);
        sommeHT += parseFloat(this.filtredTable[i].montantHT);
        arr.push(this.filtredTable[i].montantTTC);
        sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 35,
        head: [
          [
            "Nom du client",
            "Type de réglement",
            "Mode de paiement",
            "Montant HT",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            "",
            "",
            "Total : ",
            parseFloat(sommeHT).toFixed(2),
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering
      doc.save("Liste_Réglement.pdf");

      this.printLoading = false;
    },
  },
};
</script>
<style scoped>
.red-switch {
  background-color: #ff8181 !important;
}

.yellow-switch {
  background-color: #ffd968 !important;
}

.ant-descriptions-item-label {
  font-weight: bold !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
